<template>
  <div style="background-color: #f7f7f7">
    <div class="search padding">
      <h4>学习习惯数据统计</h4>
      <div class="flex">
        <el-date-picker
            v-model="form.time"
            type="monthrange"
            size="small"
            unlink-panels
            :clearable="false"
            value-format="yyyyMM"
            @change="formSelected($event,'time')"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>

        <el-select class="ml-20" size="small" v-model="form.school_id" @change="formSelected($event,'school')"
                   placeholder="请选择校区">
          <el-option
              v-for="item in schoolOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>

        <el-select class="ml-20" size="small" v-model="form.grade_id" @change="formSelected($event,'grade')"
                   placeholder="请选择年级">
          <el-option
              v-for="item in gradeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>

        <el-select class="ml-20" size="small" v-model="form.subject_id" multiple collapse-tags
                   @change="formSelected($event,'subject')" placeholder="请选择学科">
          <el-option
              v-for="item in subjectOptions"
              :key="item.subject_id"
              :label="item.name"
              :value="item.subject_id">
          </el-option>
        </el-select>

        <el-select class="ml-20" size="small" v-model="form.class_id" @change="formSelected($event,'class')"
                   placeholder="请选择教学班">
          <el-option
              v-for="item in classOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>

        <el-button size="small" style="margin-left: 20rem" type="primary" @click="topReset">重置</el-button>
      </div>
    </div>

    <div>
      <h5>
        检查任务完成情况
        <el-tooltip class="item" effect="dark" placement="bottom-start">
          <i class="el-icon-question" style="margin-left: 10rem;font-size: 16rem"></i>
          <div slot="content" style="line-height: 1.8">计算公式<br/>
            任务完成情况：<br/>
            检查任务=教学班人数*（预习完成率+笔记完成率+预习完成率+错题处理完成率）<br/>
            已完成检查=已完成的学习习惯检查数量<br/>
            未完成检查=检查任务减去已完成检查数量，若已完成数量已超过任务任务数量，展示为0<br/>
            实时完成率=1-（学习习惯未完成数量/学习习惯任务数量*100%）<br/>
            学习习惯任务数量=教学班人数*学习习惯完成率<br/>
            当实际完成数量大于任务数量，展示为100%。
          </div>
        </el-tooltip>
      </h5>
      <div class="padding mt-20" style="position: relative">
        <h6>任务完成情况</h6>
        <div ref="line" id="line"></div>
        <div v-if="!lineData.total_task.length" class="nodata">
          <t-result type='empty'></t-result>
        </div>
      </div>
      <div class="padding mt-20" style="position: relative">
        <h6 style="position: relative;z-index: 1">
          实时完成率(%)
          <el-tooltip class="item" effect="dark" placement="bottom-start">
            <i class="el-icon-question" style="margin-left: 10rem;font-size: 16rem"></i>
            <div slot="content" style="line-height: 1.8">温馨提示：<br/>
              学科实时完成率为0时，有以下三种情况：<br/>
              1.学习习惯检查任务完成为0；<br/>
              2.未配置检查任务；<br/>
              3.检查任务配置为0。<br/>
            </div>
          </el-tooltip>
        </h6>
        <div ref="bar" id="bar"></div>
        <div v-if="!barData.subject.length" class="nodata">
          <t-result type='empty'></t-result>
        </div>
      </div>
    </div>

    <div>
      <h5>
        学生习惯养成情况
        <el-tooltip class="item" effect="dark" placement="bottom-start">
          <i class="el-icon-question" style="margin-left: 10rem;font-size: 16rem"></i>
          <div slot="content" style="line-height: 1.8">计算公式<br/>
            优=检查等级优的数量/教学班人数*100%；<br/>
            良=检查等级良的数量/教学班人数*100%；<br/>
            中=检查等级中的数量/教学班人数*100%；<br/>
            差=检查等级差的数量/教学班人数*100%；<br/>
            未检查=教学班人数-已完成检查数量/教学班人数*100%。<br/>
            若已完成数量已超过任务数量，展示为0。
          </div>
        </el-tooltip>
      </h5>
      <div class="padding mt-20">
        <div ref="pie" id="pie"></div>
      </div>
    </div>

    <div class="padding mt-20 table-box" style="padding-left: 0;padding-top: 0">
      <list-template
          :loading="loading"
          :total="total"
          :current-page="page"
          :table-data="tableData"
          :table-config="tableConfig"
          :tableStyle="1"
          @onChangePage="changeCurrentPage"
          @onSearch="onSearch"
          @onReset="search = null;searchVal = null;"
          @onExportData="getTableData(1)"
          ref="table"
      >
        <template slot="title">
          <el-form ref="form" :model="formData" label-width="0">
            <div class="flex flex-wrap flex-align mb-20">
              <el-form-item>
                <el-date-picker
                    v-model="formData.time"
                    type="monthrange"
                    size="small"
                    unlink-panels
                    :picker-options="pickerOptions"
                    :clearable="false"
                    value-format="yyyyMM"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="请选择学习习惯" v-model="formData.knack_type"
                           :popper-append-to-body="false">
                  <el-option v-for="option in habitOption" :label="option.name" :value="option.type"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="请选择校区" v-model="formData.school_id" :popper-append-to-body="false"
                           @change="formDataSelected($event,'school')">
                  <el-option v-for="option in schoolConfig" :label="option.name" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="请选择年级" v-model="formData.grade_id" :popper-append-to-body="false"
                           @change="formDataSelected($event,'grade')">
                  <el-option v-for="option in gradeConfig" :label="option.name" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="请选择班级" v-model="formData.class_id" :popper-append-to-body="false"
                           @change="formDataSelected($event,'class')">
                  <el-option v-for="option in classConfig" :label="option.name" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="请选择学科" multiple collapse-tags v-model="formData.subject_id"
                           :popper-append-to-body="false" @change="formDataSelected($event,'subject')">
                  <el-option v-for="option in subjectConfig" :label="option.name"
                             :value="option.subject_id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="formData.keyword" size="small" placeholder="请输入姓名搜索"></el-input>
              </el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <el-button size="small" style="margin-left: 16rem" debounce @click.prevent="onReset">重置</el-button>
            </div>
          </el-form>
        </template>
      </list-template>
    </div>


  </div>
</template>

<script>
import {mapState} from 'vuex'
import * as echarts from 'echarts';
import tools from "@/utils/tools";
import moment from "moment";

export default {
	_config:{"route":{"path":"details","meta":{"title":"数据看板"}}},
  computed: {
    ...mapState(['page'])
  },
  data() {
    let minTime = null;
    let maxTime = null;
    return {
      // 表格搜索配置
      form: {
        time: [],
        school_id: '',
        grade_id: '',
        class_id: '',
        subject_id: [],
      },

      schoolOptions: [],
      gradeOptions: [],
      subjectOptions: [],
      classOptions: [],

      habitOption: [],
      schoolConfig: [],
      gradeConfig: [],
      classConfig: [],
      subjectConfig: [],

      echarts_color: ['#165DFF', '#30C9C9', '#F7C034', '#7B3DD1', '#469AFA'],

      line_dom: null,
      bar_dom: null,
      pie_dom: null,

      lineData: {
        x: [],
        total_task: [],
        accomplish_no_total: [],
        accomplish_total: []
      },
      barData: {
        line: '',
        subject: []
      },
      pieData: {},

      loading: true,
      formData: {
        time: [],
        knack_type: '',
        school_id: '',
        grade_id: '',
        class_id: '',
        subject_id: [],
        export: 0
      },
      sessionFormData: '',  //该数据用于存放搜索后的搜索条件，然后导出

      // 表格配置
      tableConfig: [
        {prop: 'teacher_info', label: '老师姓名'},
        {prop: 'subject_info', label: '学科'},
        {prop: 'grade_info', label: '年级',},
        {prop: 'class_info', label: '教学班',},
        {prop: 'knack_name', label: '学习习惯'},
        {label: '优', render: (row) => this.numCheck(row.data.excellent_total_rate)},
        {label: '良', render: (row) => this.numCheck(row.data.good_total_rate)},
        {label: '中', render: (row) => this.numCheck(row.data.typically_total_rate)},
        {label: '差', render: (row) => this.numCheck(row.data.wanting_total_rate)},
        {label: '未检查', render: (row) => this.numCheck(row.data.not_check_total_rate)},
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],

      pickerOptions: {
        disabledDate(time) {
          if (minTime) {
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        },
        onPick(time) {
          // 选择了两个时间放开删除限制
          if (time.maxDate && time.minDate) {
            minTime = null;
            maxTime = null;
            // 如果选择了一个时间
          } else if (!time.maxDate) {
            // 31104000000是一年的时间戳  2592000000是一个月的时间戳
            minTime = time.minDate.getTime() - 31104000000 * 3 + 2592000000;
            maxTime = time.minDate.getTime() + 31104000000 * 3;
          } else {
            // 放开限制，方便再次选择
            minTime = null;
            maxTime = null;
          }
        }
      }
    }
  },
  created() {
    let str = moment().format('yyyyMM');
    this.form.time = [str, str]
    this.formData.time = [str, str]
  },
  mounted() {
    this.$store.commit('setPage', 1)

    //echarts dom初始化
    this.echartsDomInit()

    //获取校区年级班级联动框配置
    this.commonInterface();

    //获取习惯配置
    this.getKnackType()

    //form表单数据组装
    this.formMake()

    //底部表格数据请求
    this.getTableData()

    window.addEventListener('resize', this.aaa)
  },
  destroyed() {
    window.removeEventListener('resize', this.aaa)
  },
  methods: {
    numCheck(num) {
      return num ? num + '%' : num
    },

    aaa: tools.debounce(function () {
      this.echartsDomDispose()
      this.echartsDomInit()
      this.lineInit()
      this.barInit()
      this.pieInit()
    }, true, 500),

    //echarts的dom初始化
    echartsDomInit() {
      this.line_dom = echarts.init(document.getElementById('line'));
      this.bar_dom = echarts.init(document.getElementById('bar'));
      this.pie_dom = echarts.init(document.getElementById('pie'));
    },
    //echarts的实例销毁
    echartsDomDispose() {
      this.line_dom.dispose()
      this.bar_dom.dispose()
      this.pie_dom.dispose()
    },

    //获取校区年级班级联动框配置
    commonInterface() {
      this.$_axios2.get('api/common/school').then(res => {
        this.schoolOptions = res.data.data.school;
        this.schoolConfig = res.data.data.school;
        this.subjectOptions = res.data.data.subject_data;
        this.subjectConfig = res.data.data.subject_data;
      })
    },

    //获取习惯配置
    getKnackType() {
      this.$_axios2.get('api/educational-routine/knack-list').then(res => {
        if (res.data.status === 0) {
          this.habitOption = res.data.data;
        }
      })
    },

    //form表单数据组装后请求三个图的数据
    formMake() {
      let params = JSON.parse(JSON.stringify(this.form))
      params.start = params.time[0];
      params.end = params.time[1];
      params.subject_id = params.subject_id.join(',')
      delete params.time;
      this.getStatisticsMonth(params)
      this.getStatisticsSubject(params)
      this.getStatisticsLevelChart(params)
    },

    //检查任务完成情况
    getStatisticsMonth(params) {
      this.$_axios2('api/educational-routine/statistics-month', {params}).then(res => {
        this.lineData = res.data.data;
        this.lineInit()
      })
    },

    //实时完成率
    getStatisticsSubject(params) {
      this.$_axios2('api/educational-routine/statistics-subject', {params}).then(res => {
        this.barData = res.data.data;
        this.barInit()
      })
    },

    //饼状图
    getStatisticsLevelChart(params) {
      this.$_axios2('api/educational-routine/statistics-level-chart', {params}).then(res => {
        this.pieData = res.data.data;
        this.pieInit()
      })
    },

    //顶部搜索框数据变动后搜索
    formSelected(e, type) {
      let params;
      switch (type) {
        case 'time':
          this.formMake()
          break;
        case 'school':
          this.form.grade_id = ''
          this.form.class_id = ''
          params = {
            school_id: this.form.school_id
          }
          if (this.form.subject_id) {
            params.subject_id = this.form.subject_id.join(',');
          }
          this.$_axios2.get('api/common/school', {params}).then(res => {
            let data = res.data.data;
            this.gradeOptions = data.grade;
            this.classOptions = data.teaching_data;
            this.formMake()
          })
          break;
        case 'grade':
          this.form.class_id = ''
          params = {
            school_id: this.form.school_id,
            grade_id: this.form.grade_id
          }
          if (this.form.subject_id) {
            params.subject_id = this.form.subject_id.join(',')
          }
          this.$_axios2.get('api/common/school', {params}).then(res => {
            let data = res.data.data;
            this.subjectOptions = data.subject_data;
            this.classOptions = data.teaching_data;
            this.formMake()
          })
          break;
        case 'subject':
          if (this.form.school_id) {
            params = {
              subject_id: this.form.subject_id.join(','),
              school_id: this.form.school_id
            }
            if (this.form.grade_id) {
              params.grade_id = this.form.grade_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.classOptions = data.teaching_data;
              this.formMake()
            })
          } else {
            this.formMake()
          }
          break;
        case 'class':
          if (!this.form.subject_id.length) {
            let params = {
              school_id: this.form.school_id,
              grade_id: this.form.grade_id,
              teaching_id: this.form.class_id,
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.subjectOptions = data.subject_data;
              this.formMake()
            })
          } else {
            this.formMake()
          }
          break;
      }
    },

    topReset() {
      let str = moment().format('yyyyMM');
      this.form = {
        time: [str, str],
        school_id: '',
        grade_id: '',
        class_id: '',
        subject_id: [],
      }
      this.gradeOptions = [];
      this.classOptions = [];
      this.$_axios2.get('api/common/school').then(res => {
        this.schoolOptions = res.data.data.school;
        this.subjectOptions = res.data.data.subject_data;
      })
      this.formMake()
    },

    lineInit() {
      let data = this.lineData;
      let option;
      let color = ['#F7BA1E', '#165DFF', '#14C9C9']
      option = {
        color,
        grid: {top: 60, bottom: 40, left: 40, right: 40},
        legend: {
          data: ['检查任务', '未完成', '已完成'],
          itemWidth: 15,
          itemHeight: 15,
          icon: "circle",
          itemGap: 20,
          top: 10,
          right: 50
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          alwaysShowContent: false,
          // backgroundColor: '#3491FA',
          backgroundColor: '#fff',
          borderWidth: 0,
          className: 'tooltip',
          textStyle: {
            color: '#333',
            lineHeight: 50,
            textAlign: 'center'
          },
          padding: [8, 15, 8, 15,],
          formatter: params => {
            let time = moment(params[0].axisValue).format('yyyy-MM')
            let str = `<p><span class="cir cir-1"></span>时间：${time}</p>`;
            params.forEach((item, index) => {
              let _str = `<p class="mt-10"><span class="cir" style="background-color: ${color[index]}"></span>${item.seriesName}：${item.data}</p>`;
              str += _str;
            })
            return str
          }
        },
        xAxis: {
          type: 'category',
          data: data.x,
          splitLine: {
            lineStyle: {
              type: 'solid'
            }
          },
          axisTick: {show: false}
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '检查任务',
            data: data.total_task,
            type: 'line',
            symbol: data.total_task.length === 1 ? 'emptyCircle' : 'none',
          },
          {
            name: '未完成',
            data: data.accomplish_no_total,
            type: 'line',
            symbol: data.accomplish_no_total.length === 1 ? 'emptyCircle' : 'none',
          },
          {
            name: '已完成',
            data: data.accomplish_total,
            type: 'line',
            symbol: data.accomplish_no_total.length === 1 ? 'emptyCircle' : 'none',
          }
        ]
      };

      option && this.line_dom.setOption(option);
    },

    barInit() {
      let data = this.barData;
      let source_data = [];
      data.subject.forEach(item => {
        let arr = [item.name, ...data.line[item.subject_id]]
        source_data.push(arr)
      })
      let option;
      let color = ['#165DFB', '#30C9C9', '#F7C034', '#7B3DD1', '#469AFA'];
      option = {
        color,
        grid: {
          top: 60,
          bottom: 40,
          left: 40,
          right: 40
        },
        legend: {
          data: ['预习', '笔记', '作业', '错题处理'],
          itemWidth: 15,
          itemHeight: 15,
          icon: "circle",
          itemGap: 20,
          top: 10,
          right: 50
        },
        tooltip: {
          valueFormatter: (value) => +value.toFixed(2) + '%'
        },
        dataset: {
          source: [
            ['product', '预习', '笔记', '作业', '错题处理'],
            ...source_data
          ]
        },
        xAxis: {
          type: 'category',
          splitLine: {
            lineStyle: {
              type: 'solid'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {},
        series: [{type: 'bar'}, {type: 'bar'}, {type: 'bar'}, {type: 'bar'}]
      };
      option && this.bar_dom.setOption(option);
    },

    pieInit() {
      let data = this.pieData;
      let option;

      let _data = [];
      for (let i = 1; i < 5; i++) {
        let excellent_total_rate = data[i].excellent_total_rate,
            good_total_rate = data[i].good_total_rate,
            typically_total_rate = data[i].typically_total_rate,
            wanting_total_rate = data[i].wanting_total_rate,
            not_check_total_rate = data[i].not_check_total_rate;
        let arr = [];
        if (!excellent_total_rate && !good_total_rate && !typically_total_rate && !wanting_total_rate && !not_check_total_rate) {
          arr = []
        } else {
          arr = [
            {name: '优', value: data[i].excellent_total_rate},
            {name: '良', value: data[i].good_total_rate},
            {name: '中', value: data[i].typically_total_rate},
            {name: '差', value: data[i].wanting_total_rate},
            {name: '未检查', value: data[i].not_check_total_rate},
          ]
        }
        _data.push(arr)
      }

      let title_style = {
        top: '45%',
        textAlign: 'center',
        subtextStyle: {
          fontSize: 20,
          lineHeight: 24,
          fontWeight: 'bold',
          color: '#1D2129',
        }
      }
      let series_style = {
        type: 'pie',
        radius: ['50%', '60%'],
        center: ['50%', '50%'],
        label: {
          show: false,
        },
      }
      option = {
        color: this.echarts_color,
        title: [
          {
            subtext: '预习',
            left: '12.3%',
            ...title_style
          },
          {
            subtext: '笔记',
            left: '37.2%',
            ...title_style
          },
          {
            subtext: '作业',
            left: '62.2%',
            ...title_style
          },
          {
            subtext: '错误\n处理',
            left: '87.2%',
            ...title_style,
            top: '43%'
          }
        ],
        legend: {
          data: ['优', '良', '中', '差', '未检查'],
          itemWidth: 15,
          itemHeight: 15,
          icon: "circle",
          itemGap: 40,
          bottom: 10,
          right: 'center',
          selectedMode: false
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove|click',
          alwaysShowContent: false,
          backgroundColor: '#fff',
          borderWidth: 0,
          className: 'tooltip',
          textStyle: {
            color: '#333',
            lineHeight: 50,
            textAlign: 'center'
          },
          formatter: params => {
            let index = params.seriesIndex + 1;

            let p1 = data[index].excellent_total_rate ? '%' : '',
                p2 = data[index].good_total_rate ? '%' : '',
                p3 = data[index].typically_total_rate ? '%' : '',
                p4 = data[index].wanting_total_rate ? '%' : '',
                p5 = data[index].not_check_total_rate ? '%' : '';

            let str = `<p><span class="cir" style="background-color: #165DFB"></span>优：${data[index].excellent_total_rate}${p1}</p>
<p><span class="cir" style="background-color: #30C9C9"></span>良：${data[index].good_total_rate}${p2}</p>
<p><span class="cir" style="background-color: #F7C034"></span>中：${data[index].typically_total_rate}${p3}</p>
<p><span class="cir" style="background-color: #7B3DD1"></span>差：${data[index].wanting_total_rate}${p4}</p>
<p><span class="cir" style="background-color: #469AFA"></span>未检查：${data[index].not_check_total_rate}${p5}</p>`;
            return str
          }
        },
        series: [
          {...series_style, data: _data[0], left: 0, right: '75%'},
          {...series_style, data: _data[1], left: '25%', right: '50%'},
          {...series_style, data: _data[2], left: '75%', right: '50%'},
          {...series_style, data: _data[3], left: '75%', right: 0}
        ]
      };

      option && this.pie_dom.setOption(option);

    },

    //底部搜索框数据变动后搜索
    formDataSelected(e, type) {
      let params;
      switch (type) {
        case 'time':
          // this.getTableData()
          break;
        case 'school':
          this.formData.grade_id = ''
          this.formData.class_id = ''
          params = {
            school_id: this.formData.school_id
          }
          if (this.formData.subject_id) {
            params.subject_id = this.formData.subject_id.join(',');
          }
          this.$_axios2.get('api/common/school', {params}).then(res => {
            let data = res.data.data;
            this.gradeConfig = data.grade;
            this.classConfig = data.teaching_data;
            // this.getTableData()
          })
          break;
        case 'grade':
          this.formData.class_id = ''
          params = {
            school_id: this.formData.school_id,
            grade_id: this.formData.grade_id
          }
          if (this.formData.subject_id) {
            params.subject_id = this.formData.subject_id.join(',')
          }
          this.$_axios2.get('api/common/school', {params}).then(res => {
            let data = res.data.data;
            this.subjectConfig = data.subject_data;
            this.classConfig = data.teaching_data;
            // this.getTableData()
          })
          break;
        case 'subject':
          if (this.formData.school_id) {
            params = {
              subject_id: this.formData.subject_id.join(','),
              school_id: this.formData.school_id
            }
            if (this.formData.grade_id) {
              params.grade_id = this.formData.grade_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.classConfig = data.teaching_data;
              // this.getTableData()
            })
          } else {
            // this.getTableData()
          }
          break;
        case 'class':
          if (!this.formData.subject_id.length) {
            let params = {
              school_id: this.formData.school_id,
              grade_id: this.formData.grade_id,
              teaching_id: this.formData.class_id,
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.subjectConfig = data.subject_data;
              // this.getTableData()
            })
          } else {
            // this.getTableData()
          }
          break;
      }
    },

    getTableData(download = 0) {
      this.loading = true
      let params;
      if (download) {
        params = this.sessionFormData;
        params.export = download;
        this.$_axios2('api/educational-routine/statistics-list', {params, responseType: 'blob'}).then(res => {
          // console.log(res.headers['content-disposition'])
          let filename = '';
          let matches = /filename[^;=\n]*=(((["']).*?\3)|[^;\n]*)/.exec(res.headers['content-disposition']);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/["']/g, '');
          }
          filename = decodeURI(filename).slice(5)
          const blob = new Blob([res.data])
          let downloadElement = document.createElement('a')
          let href = window.URL.createObjectURL(blob)
          downloadElement.href = href
          downloadElement.download = decodeURIComponent(filename)
          document.body.appendChild(downloadElement)
          downloadElement.click()
          document.body.removeChild(downloadElement)
          window.URL.revokeObjectURL(href)
        }).finally(() => {
          this.loading = false
        })
      } else {
        params = JSON.parse(JSON.stringify(this.formData))
        params.start = params.time[0];
        params.end = params.time[1];
        params.export = download;
        params.page = this.page;
        params.subject_id = params.subject_id ? params.subject_id.join(',') : params.subject_id
        delete params.time;
        this.sessionFormData = params;
        this.$_axios2('api/educational-routine/statistics-list', {params}).then(res => {
          if (res.data.status === 0) {
            let data = res.data.data;
            this.tableData = data.list;
            this.total = data.page.total
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },

    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getTableData()
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      // this.search = val
      this.getTableData()
    },

    onReset() {
      let str = moment().format('yyyyMM');
      this.formData = {
        time: [str, str],
        knack_type: '',
        school_id: '',
        grade_id: '',
        class_id: '',
        subject_id: '',
        export: 0
      }
      this.gradeConfig = [];
      this.classConfig = []
      this.$_axios2.get('api/common/school').then(res => {
        this.schoolConfig = res.data.data.school;
        this.subjectConfig = res.data.data.subject_data;
      })
      this.getTableData()
    }
  },

}
</script>

<style scoped lang="scss">
::v-deep .el-date-editor .el-range-separator {
  width: 24rem !important;
  color: rgba(0, 0, 0, .25);
}

::v-deep {
  .el-form-item {
    margin-right: 20rem;
    margin-bottom: 6rem;
  }
}

::v-deep .el-form .el-input {
  width: auto !important;
}

h4 {
  margin-bottom: 20rem;
  font-size: 22rem;
}

h5 {
  color: #1D2129;
  font-size: 18rem;
  margin: 22rem 0 0 22rem;
}

h6 {
  position: absolute;
  top: 30rem;
  left: 32rem;
  color: #1D2129;
  font-size: 14rem;
}

.padding {
  padding: 20rem;
  background-color: #fff;
}

#line {
  height: 400rem;
}

#bar {
  height: 400rem;
}

#pie {
  height: 400rem;
}

::v-deep .table-box .el-range-editor--small.el-input__inner {
  width: 200rem !important;
}

::v-deep .table-box .el-form .el-input {
  max-width: 177rem;
}

::v-deep .cir {
  display: inline-block;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin-right: 8rem;
}

.nodata {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

@media only screen and (min-width: 1140rem) {
  ::v-deep .el-form .el-input {
    width: auto !important;
  }
}
</style>
